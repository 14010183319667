import * as React from 'react'

import { MappingsTableRow, MappingsTableCell } from './MappingTable.styles'

export const MappingRow = ({ mapping }) => {
  const cellValues = mapping.field.attribute.split('.')
  return (
    <MappingsTableRow>
      {cellValues.map(cellValue => (
        <MappingsTableCell>{cellValue}</MappingsTableCell>
      ))}
    </MappingsTableRow>
  )
}
