import React, { useState, useMemo, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import { parse, end } from 'iso8601-duration'
import * as _ from 'lodash'

import { useAuth } from '@jeeves/components/Auth'

const REQUEST_ACCESS = gql`
  mutation UpsertApprovalRequest($repoId: ID!, $request: UpsertApprovalRequestInput!) {
    upsertApprovalRequest(repoId: $repoId, request: $request) {
      approvalRequest {
        validUntil
      }
    }
  }
`

const useRequestAccess = () => {
  const [requestAccess, { data, loading, error }] = useMutation(REQUEST_ACCESS, { onError: error => console.log(error) })

  return {
    loading,
    error,
    data,
    requestAccess
  }
}

export default useRequestAccess
