/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { green, blueGrey } from '@material-ui/core/colors'
import CheckCircle from '@material-ui/icons/CheckCircleRounded'

import {
  Button,
  SetupLayout,
  Title,
  Typography,
  SkipLink,
  NextButton,
} from '@jeeves/pages/Setup/components'
import SlackLogoUrl from './slackLogo.svg'

const SlackLogo = () => (
  <img
    src={SlackLogoUrl}
    alt=""
    css={() => css`
      display: block;
      max-width: 128px;
      margin: -48px auto -16px auto;
    `}
  />
)

const SlackList = styled.ul`
  list-style-type: none;
  margin: 0 0 24px 0;
  padding: 0;
  min-width: 550px;
  width: 550px;
`

const SlackListItem = ({ children }) => (
  <li
    css={() => css`
      padding: 12px 0;
      margin-bottom: 1px solid ${blueGrey[300]};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    `}
  >
    <div
      css={() => css`
        flex-grow: 0;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
      `}
    >
      <CheckCircle nativeColor={green[700]} />
    </div>
    <Typography
      css={() => css`
        width: 90%;
        margin: 0;
        padding: 0;
      `}
    >
      {children}
    </Typography>
  </li>
)

export const ConfigureSlack = ({ onNext, slackConnected, postSlackInvite }) => (
  <SetupLayout onNext={onNext} hideButton={true} absoluteIndex={true}>
    <div
      css={() => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: space-between;
      `}
    >
      <SlackLogo />

      <div
        css={() => css`
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          align-items: center;
          padding: 0 24px;
        `}
      >
        <Title>Join us on Slack</Title>
        <SlackList>
          <SlackListItem>You can talk to our engineers for support</SlackListItem>
          <SlackListItem>We’ll invite you to our Slack as a guest</SlackListItem>
          <SlackListItem>
            You can always contact us at&nbsp;
            <a href="mailto:support@cyral.com">support@cyral.com</a>
          </SlackListItem>
        </SlackList>
        <Button onClick={postSlackInvite} disabled={slackConnected}>
          {slackConnected ? 'Check your email for a Slack invite' : 'Join'}
        </Button>
        {slackConnected ? (
          // <Button css={{ marginTop: '30px' }} onClick={onNext}>
          //   Next
          // </Button>
          <NextButton css={{ marginTop: '30px' }} variant="contained" onClick={onNext} />
        ) : (
          <SkipLink
            onNext={onNext}
            text={slackConnected ? 'Continue' : "I'll do this later"}
          ></SkipLink>
        )}
      </div>
    </div>
  </SetupLayout>
)
