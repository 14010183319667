import { z } from 'zod'

const baseSchema = z.object({
  allowNativeAuthentication: z.boolean(),
})

const noAuthenticationMethodSchema = z
  .object({
    authenticationMethodType: z.enum(['none']),
  })
  .merge(baseSchema)

const identityProviderSchema = z
  .object({
    authenticationMethodType: z.enum(['identityProvider']),
    identityProviderId: z.string().min(1, { message: 'Please select an identity provider.' }),
  })
  .merge(baseSchema)

const awsIamIntegrationSchema = z
  .object({
    authenticationMethodType: z.enum(['awsIamIntegration']),
    awsIamIntegrationId: z
      .string()
      .min(1, { message: 'Please select an AWS IAM authentication integration.' }),
  })
  .merge(baseSchema)

export const authenticationFormSchema = z.discriminatedUnion('authenticationMethodType', [
  noAuthenticationMethodSchema,
  identityProviderSchema,
  awsIamIntegrationSchema,
])

export type AuthenticationFormSchema = z.infer<typeof authenticationFormSchema>
