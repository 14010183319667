/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Link, TableCell, Tooltip } from '@material-ui/core/'
import { get as lodashGet } from 'lodash'

const EditGroupButton = ({ group, openEditGroupDialog, user }) => {
  const handleOnOpen = () => {
    openEditGroupDialog()
  }

  const hasUpdatePermission = lodashGet(
    user,
    'app_metadata.authorization.permissions',
    []
  ).includes('roles:update')

  return (
    <TableCell
      key={group}
      css={css`
        text-align: center;
        padding: 4px 24px;
        width: 130px;
      `}
    >
      <Tooltip title="Edit role">
        <Link
          css={{ fontFamily: 'Open Sans', fontSize: '12px', textDecoration: 'underline' }}
          component="button"
          onClick={hasUpdatePermission ? handleOnOpen : null}
        >
          {group}
        </Link>
      </Tooltip>
    </TableCell>
  )
}

export default EditGroupButton
