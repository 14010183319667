import { ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'
import { useAppConfig } from '@jeeves/hooks'
import { ServiceIcon } from '@jeeves/new-components'
import sortBy from 'lodash/sortBy'

const useRepoTypeSelect = () => {
  const { license } = useAppConfig()

  const licenseRepositories =
    license.features
      ?.filter(feature => feature.internalName === 'AllowedRepositories')
      .map(repo => repo.internalSubFeatureName) ?? []

  const isRepoTypeEnabledWithReason = repoType => {
    if (!licenseRepositories.includes(repoType)) {
      return { enabled: false, reason: 'Contact support to enable this repository.' }
    }
    return { enabled: true }
  }

  const options = ALL_REPO_TYPES_WITH_DISPLAY_NAME.map(({ typeName, displayName }) => {
    const { enabled } = isRepoTypeEnabledWithReason(typeName)
    return {
      icon: <ServiceIcon type={typeName} />,
      label: displayName,
      value: typeName,
      disabled: !enabled,
    }
  })

  return {
    options: sortBy(options, ['label']),
  }
}

export default useRepoTypeSelect
