/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { green, blueGrey } from '@material-ui/core/colors'

export const SkipLink = ({ text, onNext }) => (
  <Typography
    css={() => css`
      text-decoration: underline;
      color: ${blueGrey[600]};
      font-size: 1.1rem;
      transition: color 0.1s linear;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        color: ${blueGrey[800]};
      }
    `}
    onClick={onNext}
  >
    {text}
  </Typography>
)
