import React from 'react'

import Table from '@mui/material/Table'

import Box from '@mui/material/Box'

import { MappingHeader } from '@jeeves/pages/DataLabels/components/RepoRow/MappingTable/MappingHeaders'
import { MappingRow } from './MappingRow'

export const MappingsTable = ({ children, props, mapping, repoType, datalabels, repoId }) => {
  return (
    <Box>
      <Table>
        <MappingHeader
          actionsCol={true}
          repoType={repoType}
          backgroundColor="#F0F2F5"
        ></MappingHeader>
        {mapping.datamapMappings.map(datamapMapping => (
          <MappingRow
            repoType={repoType}
            repoId={repoId}
            datalabels={datalabels}
            mapping={datamapMapping}
            labelName={mapping.node.name}
          ></MappingRow>
        ))}
      </Table>
    </Box>
  )
}
