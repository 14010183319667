import React from 'react'

import { InlineCode } from '@jeeves/new-components'
import { getRedactedPassword, createEscapedConnectionString } from '../utils'

import { DBeaver } from './components/DBeaver'

const getConnectionTypes = (connectionInfo, options) => {
  const { showPassword, jdbcDriverVersion } = options

  const mysqlCliShellCommandConnectionInfo = {
    ...connectionInfo,
    port: connectionInfo.ports[0],
  }

  return [
    {
      label: 'MySQL CLI',
      value: 'mysql-cli',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getMysqlCliShellCommand({
            connectionInfo: mysqlCliShellCommandConnectionInfo,
            redactPassword: !showPassword,
          })}
        </InlineCode>
      ),
      copyValue: getMysqlCliShellCommand({
        connectionInfo: mysqlCliShellCommandConnectionInfo,
      }),
    },
    {
      label: 'DBeaver',
      value: 'dbeaver',
      content: <DBeaver />,
      copyValue: null,
    },
    {
      label: 'JDBC URL',
      value: 'jdbc',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getJdbcUrl({
            connectionInfo,
            options: {
              showPassword,
              jdbcDriverVersion,
            },
          })}
        </InlineCode>
      ),
      copyValue: getJdbcUrl({
        connectionInfo,
        options: {
          jdbcDriverVersion,
        },
      }),
    },
  ]
}

// Note: Both MySQL and MariaDB use their own versions of the mysql CLI tool
const getMysqlCliShellCommand = ({ repoType, connectionInfo, redactPassword = false }) => {
  const { username, host, port, password: connectionInfoPassword } = connectionInfo

  const password = redactPassword
    ? getRedactedPassword(connectionInfoPassword)
    : connectionInfoPassword

  const additionalOptions =
    '--default-auth=mysql_clear_password --enable-cleartext-plugin --ssl-mode=REQUIRED'

  return `mysql -u ${username} -p${password} -h ${host} -P ${port} ${additionalOptions}`
}

const getJdbcUrl = ({ connectionInfo, options = {} }) => {
  const { password, ...rest } = connectionInfo
  const { showPassword = true, jdbcDriverVersion } = options

  return createEscapedConnectionString({
    scheme: 'jdbc:mysql',
    connectionInfo: {
      ...rest,
      password: showPassword ? password : getRedactedPassword(password),
    },
    options: {
      authenticationPlugins:
        jdbcDriverVersion === 8
          ? 'com.mysql.cj.protocol.a.authentication.MysqlClearPasswordPlugin'
          : 'com.mysql.jdbc.authentication.MysqlClearPasswordPlugin',
      disabledAuthenticationPlugin:
        jdbcDriverVersion === 8
          ? 'com.mysql.cj.protocol.a.authentication.MysqlNativePasswordPlugin'
          : 'com.mysql.jdbc.authentication.MysqlNativePasswordPlugin',
      defaultAuthenticationPlugin:
        jdbcDriverVersion === 8
          ? 'com.mysql.cj.protocol.a.authentication.MysqlClearPasswordPlugin'
          : 'com.mysql.jdbc.authentication.MysqlClearPasswordPlugin',
    },
  })
}

export default getConnectionTypes
