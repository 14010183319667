/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useContext } from 'react'
import { Button, SetupLayout, Typography } from '@jeeves/pages/Setup/components'
import { SetupContext } from '../../SetupContext'

import DrHooUrl from './dr-hoo-thumbs.png'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const endDate = '2021-02-07T11:10:01.547560264Z'

export const Finish = ({ license, postNotification, COMPLETED_WIZARD }) => {
  const { completeWizard } = useContext(SetupContext)
  const history = useHistory()

  return (
    <SetupLayout
      title="Hooray!"
      subtitle="Enjoy using our service"
      done={100}
      actionButton={
        <Button
          onClick={async () => {
            await completeWizard()
            await postNotification(COMPLETED_WIZARD)
            history.push('/home')
          }}
        >
          Launch Cyral
        </Button>
      }
      absoluteIndex={true}
    >
      <div
        css={() => css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={() => css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            max-width: 256px;
          `}
        >
          <img
            src={DrHooUrl}
            css={() => css`
              display: block;
              width: 100%;
            `}
            alt="Welcome to Cyral!"
          />
        </div>
        <div
          css={() => css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 16px;
          `}
        >
          {/* <Typography>{`Your free trial has begun! You have access until ${new Date(
            license.endDate
          ).toDateString()}.`}</Typography> */}
          {/* {license && license.endDate && (
            <Typography>{`Your free trial has begun! You have access until ${moment(
              license.endDate
            ).format('MMMM DD, YYYY')}.`}</Typography>
          )} */}
        </div>
      </div>
    </SetupLayout>
  )
}
