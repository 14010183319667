/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import { MenuItem } from './components/MenuItem'
import DashboardIcon from '@material-ui/icons/Dashboard'

const Spacer = styled.div`
  margin: 1rem 0;
`

const SandboxSection = ({ iconOnly }) => {
  return (
    <MenuList>
      <MenuItem
        component={NavLink}
        to="/sandbox"
        iconOnly={iconOnly}
        // disabled={!hasPermission('policies:read')}
        primaryText="Sandbox"
        listItemIcon={<DashboardIcon fontSize="small" />}
      />
    </MenuList>
  )
}

export default SandboxSection
