import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Box, FormControl, Stack, Typography } from '@mui/material'
import { Button, Input, InputLabel, Tag, InlineCode } from '@jeeves/new-components'

const Tags = () => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  })

  const currentTag = watch('currentTag')

  const handleAddTag = () => {
    append(currentTag)
    setValue('currentTag', '')
  }

  return (
    <Stack spacing={1}>
      <InputLabel htmlFor="tag-input">Tags</InputLabel>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        By default, tags are private and will only be visible to admins. To display a tag in the
        Access Portal, preface your tag label with <InlineCode>public:</InlineCode>.
      </Typography>

      <Box
        sx={{
          border: 1,
          borderColor: 'cyralColors.grey.200',
          borderRadius: theme => theme.radii.base,
          height: '77px',
          padding: 1,
          overflowY: 'auto',
        }}
      >
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
          {fields.map((_, index) => (
            <Controller
              name={`tags[${index}]`}
              control={control}
              render={({ field }) => (
                <Tag key={field.id} onRemove={() => remove(index)}>
                  {field.value}
                </Tag>
              )}
            />
          ))}
        </Stack>
      </Box>

      <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
        <FormControl variant="standard" sx={{ flex: 1 }}>
          <Input
            id="tag-input"
            inputProps={{
              ...register('currentTag'),
            }}
            placeholder="Add a tag..."
          />
        </FormControl>

        <Button
          disabled={isSubmitting || currentTag?.length === 0}
          onClick={handleAddTag}
          size="small"
          variant="outlined"
          color="secondary"
        >
          Add
        </Button>
      </Stack>
    </Stack>
  )
}

export default Tags
