import { useContext } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/hooks/usePopup'
import { SetupContext } from '@jeeves/pages/Setup/SetupContext'

const useSetup = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const addUser = async user => {
    const userObj = { email: user.email, first_name: user.name, last_name: '', role: user.role.id }
    try {
      await ec.post('/users', userObj)
    } catch (e) {}
  }

  const getUsers = async () => {
    try {
      const users = await ec.get('/users')
    } catch (e) {
      console.error(e)
    }
  }

  return {
    addUser,
  }
}

export default useSetup
