import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { useForm, Controller } from 'react-hook-form'

import {
  InputLabel,
  Input,
  DialogTitle,
  DialogActions,
  DialogContent,
  ActionsDivider,
} from '@jeeves/pages/DataLabels/Custom/Dialogs/Dialogs.styles.js'

import { LearnMore } from '../components/LearnMore'
import { gql, useMutation } from '@apollo/client'
import { FETCH_MAPPINGS } from '../../Mappings'
import { getRepoFieldConstants } from '../Add/Add'

const DELETE_MAPPING = gql`
  mutation DeleteDatamapMapping_Edit($repoId: String!, $datalabelName: String!, $attribute: String!) {
    deleteDatamapMapping(repoId: $repoId, datalabelName: $datalabelName, attribute: $attribute) {
      message
    }
  }
`

const CREATE_MAPPING = gql`
  mutation UpsertDatamapMapping($repoId: String!, $datalabelName: String!, $attribute: String!) {
    upsertDatamapMapping(repoId: $repoId, datalabelName: $datalabelName, attribute: $attribute) {
      message
    }
  }
`

export const EditMapping = ({
  open,
  setOpen,
  labelName,
  attribute,
  datalabels,
  repoId,
  repoType,
}) => {
  const {
    getValues,
    reset,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      datalabel: labelName,
      location0: attribute[0],
      location1: attribute[1],
      location2: attribute[2] ? attribute[2] : null,
    },
  })

  const [createMapping, { data: createData, loading: createLoading, error: errorLoading }] =
    useMutation(CREATE_MAPPING, {
      refetchQueries: [FETCH_MAPPINGS],
    })

  const [deleteMapping, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_MAPPING)

  const repoFieldConstants = getRepoFieldConstants(repoType)

  const handleOnClose = () => {
    setOpen(false)
    reset()
  }

  const onSubmit = async () => {
    const { datalabel, location0, location1, location2 } = getValues()
    const newAttribute = location2
      ? [location0, location1, location2].join('.')
      : [location0, location1].join('.')

    try {
      await deleteMapping({
        variables: { repoId, datalabelName: datalabel, attribute: attribute.join('.') },
      })
      await createMapping({
        variables: { repoId, datalabelName: datalabel, attribute: newAttribute },
      })
      handleOnClose()
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const datalabelWatch = watch('datalabel')
  const location0Watch = watch('location0')
  const location1Watch = watch('location1')
  const location2Watch = watch('location2')

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth sx={{ radius: '12px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Mapping</DialogTitle>

        <DialogContent>
          <LearnMore> </LearnMore>
          <Stack sx={{ mt: 0.5 }}>
            <InputLabel>Data Label</InputLabel>
            <Controller
              name="datalabel"
              control={control}
              render={({ field }) => (
                <Select disabled fullWidth {...field} input={<Input />}>
                  {datalabels.map(datalabel => (
                    <MenuItem value={datalabel.name}>{datalabel.name}</MenuItem>
                  ))}
                </Select>
              )}
            />

            <InputLabel>{repoFieldConstants.inputLabel[0]}</InputLabel>
            <Controller
              name="location0"
              control={control}
              render={({ field }) => <Input {...field} fullWidth input={<Input />}></Input>}
            />

            <InputLabel>{repoFieldConstants.inputLabel[1]}</InputLabel>
            <Controller
              name="location1"
              control={control}
              render={({ field }) => <Input {...field} fullWidth input={<Input />}></Input>}
            />

            {repoType !== 's3' && repoType !== 'dynamodb' && repoType !== 'dynamodbstreams' && (
              <React.Fragment>
                <InputLabel>{repoFieldConstants.inputLabel[2]}</InputLabel>
                <Controller
                  name="location2"
                  control={control}
                  render={({ field }) => <Input {...field} fullWidth input={<Input />}></Input>}
                />
              </React.Fragment>
            )}
          </Stack>
        </DialogContent>
        <ActionsDivider></ActionsDivider>
        <DialogActions>
          <Button onClick={handleOnClose} color="primary">
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={
              !location0Watch ||
              (repoType !== 's3' && !location1Watch) ||
              (repoType !== 's3' && repoType !== 'dynamodb' && repoType !== 'dynamodbstreams' && !location2Watch)
            }
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
