/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import MuiMenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const UnstyledMenuItem = ({ listItemIcon, iconOnly, primaryText, ...props }) => (
  <MuiMenuItem {...props}>
    <ListItemIcon>{listItemIcon}</ListItemIcon>
    {!iconOnly && (
      <ListItemText
        primary={primaryText}
        primaryTypographyProps={{ variant: 'caption' }}
        // css={{ paddingLeft: '24px' }}
      />
    )}
  </MuiMenuItem>
)

export const MenuItem = styled(UnstyledMenuItem)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing[6]};
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    & * {
      margin-right: 0;
      margin-left: 0;
      color: white;
    }

    &:hover,
    &.active {
      background-color: ${theme.palette.hoverBlue};
    }

    &.active svg {
      fill: ${theme.palette.lightBlue};
    }

    &.active::before {
      content: ' ';
      background-color: ${theme.palette.lightBlue};
      width: 10px;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }
  `}
`
