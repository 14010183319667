/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect, useContext } from 'react'
import { Table, TableBody, TableFooter, TableRow } from '@material-ui/core'

import Toolbar from '@jeeves/components/Toolbar'
import { Paper } from '@jeeves/components/Primitives'
import { stableSort, getSorting } from '@jeeves/utils'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import RowSpan from '@jeeves/components/RowSpan'
import { useAuth } from '@jeeves/components/Auth'
import Loading from '@jeeves/components/Loading'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import ExpressClient from '@jeeves/clients/express'

import AddAccount from './components/AddUser'
import UserRow from './components/UserRow'
import EditUser from './components/EditUser'
import UsersManagement from '../UsersManagement'

import useAccounts from '../hooks/useAccounts'
import { AccountsContext, AccountsProvider } from '../contexts/AccountsContext'

import { getDocsURL } from '@jeeves/utils/docsURL'

const Users = () => {
  const { getTokenSilently, hasPermission } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  // eslint-disable-next-line
  const [state, setState] = useContext(AccountsContext)
  const { rowsPerPage, page, order, orderBy } = state
  const [usersLoading, setUsersLoading] = useState(false)
  const [error, setError] = useState(false)
  const [disableAddUser, setDisableAddUser] = useState(false)

  const {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    users,
    getUsers,
    getRoles,
    editUser,
    editDialogIsOpen,
    openEdit,
    closeEdit,
    updateUser,
    roles,
    deleteUser,
    crudCount,
    loading,
    closeAdd,
    createUser,
    addDialogIsOpen,
    openAdd,
    permissionsDialogIsOpen,
    openPermissionsDialog,
    closePermissionsDialog,
    openEditGroupDialog,
    closeEditGroupDialog,
    lodashGet,
  } = useAccounts()

  const { setPopup, popupTypes } = usePopup()

  const fetchData = async () => {
    setError(false)
    setUsersLoading(true)
    try {
      await getUsers()
      const { data } = await ec.get('/integrations/okta')
      if (data.length > 0) {
        setDisableAddUser(true)
      }
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      if (popupMessage) {
        setPopup(popupTypes.ERROR, popupMessage)
      }
      setError(true)
    } finally {
      setUsersLoading(false)
    }
  }

  const fetchUsers = async () => {
    setError(false)
    setUsersLoading(true)
    try {
      await getUsers()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      if (popupMessage) {
        setPopup(popupTypes.ERROR, popupMessage)
      }
      setError(true)
    } finally {
      setUsersLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
    getRoles()
  }, []) // eslint-disable-line

  useEffect(() => {
    fetchUsers()
    getRoles()
  }, [crudCount]) // eslint-disable-line

  if (!hasPermission('users:read')) {
    return null
  }

  const columns = [
    { id: 'isSSO', numeric: false, disablePadding: true, label: '' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortDefault: true },
    // { id: 'mfa', numeric: false, label: '2FA' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'role.name', numeric: false, disablePadding: false, label: 'Role' },
  ]

  return (
    <Paper css={{ position: 'relative' }}>
      <Toolbar
        title="Account Users"
        subtitle="Everyone who needs to use Cyral Control Plane"
        link={getDocsURL({ docsPath: 'account-administration/acct-cyral-control-plane-users' })}
        titleAction={
          window._env_.outbound_internet !== 'false' && (
            <AddAccount
              onOpen={openAdd}
              onClose={closeAdd}
              onSubmit={createUser}
              dialogOpen={addDialogIsOpen}
              roles={roles}
              loading={loading}
              disabled={disableAddUser}
            />
          )
        }
      >
        {hasPermission('roles:read') && (
          <UsersManagement
            onOpen={openPermissionsDialog}
            onClose={() => {
              getRoles()
              closePermissionsDialog()
            }}
            dialogOpen={permissionsDialogIsOpen}
            openEditGroupDialog={openEditGroupDialog}
            closeEditGroupDialog={closeEditGroupDialog}
          />
        )}
      </Toolbar>

      <Table>
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          rowCount={users.length}
        />

        <TableBody>
          {usersLoading && (
            <RowSpan colSpan={columns.length}>
              <Loading />
            </RowSpan>
          )}
          {!usersLoading && error && (
            <RowSpan colSpan={columns.length}>There was a problem loading users</RowSpan>
          )}
          {!usersLoading && !error && users.length === 0 && (
            <RowSpan colSpan={columns.length}>There are no users added yet</RowSpan>
          )}
          {!usersLoading &&
            stableSort(users, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(user => (
                <UserRow
                  user={user}
                  key={user.id}
                  openEdit={openEdit}
                  canEdit={hasPermission('users:update')}
                />
              ))}
        </TableBody>

        <EditUser
          user={editUser}
          onClose={closeEdit}
          onUpdate={updateUser}
          dialogOpen={editDialogIsOpen}
          roles={roles}
          deleteUser={deleteUser}
          loading={loading}
        />

        <TableFooter>
          <TableRow>
            <TablePagination
              count={users.length}
              colSpan={20}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

const UsersWrapper = props => (
  <AccountsProvider>
    <Users {...props}></Users>
  </AccountsProvider>
)

export default UsersWrapper
