import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { AuthenticationFormSchema, authenticationFormSchema } from '../helpers/schema'

const UPDATE_REPO_AUTHENTICATION_CONFIG = graphql(`
  mutation UpdateRepoAuthenticationConfig(
    $repoId: ID!
    $input: UpdateRepoAuthenticationConfigInput!
  ) {
    updateRepoAuthenticationConfig(repoId: $repoId, input: $input) {
      repo {
        id
        config {
          authentication {
            allowNativeAuthentication
            ... on MongoDBRepoAuthenticationConfig {
              authenticationMethod {
                ... on IdentityProvider {
                  id
                }
                ... on AwsIamIntegration {
                  id
                }
              }
            }
            ... on StandardRepoAuthenticationConfig {
              identityProvider {
                id
              }
            }
          }
        }
      }
    }
  }
`)

const useAuthentication_QueryFragment = graphql(`
  fragment useAuthentication_Query on Query {
    repo(id: $repoId) {
      id
      config {
        authentication {
          allowNativeAuthentication
          ... on MongoDBRepoAuthenticationConfig {
            authenticationMethod {
              ... on IdentityProvider {
                id
              }
              ... on AwsIamIntegration {
                id
              }
            }
          }
          ... on StandardRepoAuthenticationConfig {
            identityProvider {
              id
            }
          }
        }
      }
    }
  }
`)

interface useAuthenticationProps {
  query: FragmentType<typeof useAuthentication_QueryFragment>
}

export const useAuthentication = ({ query: queryProp }: useAuthenticationProps) => {
  const { repo } = useFragment(useAuthentication_QueryFragment, queryProp)

  const getStandardRepoDefaultValues = (
    authentication: Extract<
      typeof repo.config.authentication,
      { __typename: 'StandardRepoAuthenticationConfig' }
    >
  ) => {
    const identityProviderId = authentication.identityProvider?.id || ''
    const allowNativeAuthentication = authentication.allowNativeAuthentication

    if (!identityProviderId) {
      return {
        allowNativeAuthentication,
        authenticationMethodType: 'none' as const,
      }
    }

    return {
      allowNativeAuthentication,
      authenticationMethodType: 'identityProvider' as const,
      identityProviderId,
    }
  }

  const getMongoRepoDefaultValues = (
    authentication: Extract<
      typeof repo.config.authentication,
      { __typename: 'MongoDBRepoAuthenticationConfig' }
    >
  ) => {
    const authenticationMethod = authentication.authenticationMethod
    const allowNativeAuthentication = authentication.allowNativeAuthentication

    const authenticationMethodType = !authenticationMethod
      ? 'none'
      : authenticationMethod.__typename === 'IdentityProvider'
      ? 'identityProvider'
      : 'awsIamIntegration'

    if (authenticationMethodType === 'none') {
      return {
        allowNativeAuthentication,
        authenticationMethodType: 'none' as const,
      }
    }

    // This will be either an IdentityProvider or an AwsIamIntegration id
    const authenticationMethodId = authenticationMethod?.id || ''

    if (authenticationMethodType === 'identityProvider') {
      return {
        allowNativeAuthentication,
        authenticationMethodType: 'identityProvider' as const,
        identityProviderId: authenticationMethodId,
      }
    }

    return {
      allowNativeAuthentication,
      authenticationMethodType: 'awsIamIntegration' as const,
      awsIamIntegrationId: authenticationMethodId,
    }
  }

  const getDefaultValues = (): AuthenticationFormSchema => {
    const { authentication } = repo.config

    const isMongoDbRepoAuthConfig = authentication.__typename === 'MongoDBRepoAuthenticationConfig'

    if (isMongoDbRepoAuthConfig) {
      return getMongoRepoDefaultValues(authentication)
    }

    return getStandardRepoDefaultValues(authentication)
  }

  const methods = useForm<AuthenticationFormSchema>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(authenticationFormSchema),
  })

  const { getValues, reset, handleSubmit } = methods

  const { toast } = useToast()

  const [updateRepoAuthenticationConfig, { loading }] = useMutation(
    UPDATE_REPO_AUTHENTICATION_CONFIG,
    {
      onCompleted: () => {
        const values = getValues()
        reset({
          allowNativeAuthentication: values.allowNativeAuthentication,
          authenticationMethodType: values.authenticationMethodType,
          awsIamIntegrationId:
            values.authenticationMethodType === 'awsIamIntegration'
              ? values.awsIamIntegrationId
              : '',
          identityProviderId:
            values.authenticationMethodType === 'identityProvider' ? values.identityProviderId : '',
        })
      },
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while saving the authentication config for this repo, please try again.',
        })
      },
    }
  )

  const onSubmit = async (formValues: AuthenticationFormSchema) => {
    const { allowNativeAuthentication, authenticationMethodType } = formValues

    await updateRepoAuthenticationConfig({
      variables: {
        repoId: repo.id,
        input: {
          allowNativeAuthentication,
          authenticationMethod:
            authenticationMethodType === 'none'
              ? null
              : authenticationMethodType === 'identityProvider'
              ? { identityProviderId: formValues.identityProviderId }
              : { awsIamIntegrationId: formValues.awsIamIntegrationId },
        },
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit: handleSubmit(onSubmit),
  }
}
