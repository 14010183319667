/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useContext, Fragment } from 'react'
import { DialogContent, DialogTitle, IconButton, Typography, withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'

import { Button, Dialog } from '@jeeves/components/Primitives'
import { UsersProvider, UsersContext } from './contexts/UsersContext'
import useUsers from './hooks/useUsers'
import Loading from '../../../components/Loading'
import ManagementTable from './components/ManagementTable'
import CreateGroup from './components/CreateGroup'
import { useAppConfig } from '@jeeves/hooks'

const CloseButton = ({ onClose }) => (
  <IconButton
    aria-label="close"
    onClick={onClose}
    css={css`
      position: absolute;
      right: 12px;
      top: 12px;
      color: ${grey[500]};
    `}
  >
    <Close />
  </IconButton>
)

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
    fontSize: '20px',
  },
  content: {
    paddingBottom: theme.spacing.unit * 10,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    color: theme.palette.grey[500],
  },
})

const UsersManagement = props => {
  const { classes, onClose, dialogOpen, onOpen, openEditGroupDialog, closeEditGroupDialog } = props
  // eslint-disable-next-line
  const [state, setState] = useContext(UsersContext)
  const { refreshRoles, refreshGroups, refreshConnectionNames } = useUsers()
  const [loading, setLoading] = useState()
  const { license } = useAppConfig()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await Promise.all([refreshRoles(), refreshGroups(), refreshConnectionNames()])
      setLoading(false)
    }

    if (dialogOpen) fetchData()
    // TO DO: dont do this, update it on modifications
  }, [dialogOpen]) // eslint-disable-line

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={onOpen} style={{ marginRight: '16px' }}>
        Manage Roles
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          Manage Roles
          <CloseButton onClose={onClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          {loading ? (
            <div css={{ padding: '24px' }}>
              <Loading />
            </div>
          ) : (
            <Fragment>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                `}
              >
                <div>
                  <Typography variant="caption">
                    The following table highlights which permissions are granted to each roles.
                  </Typography>
                </div>
                <CreateGroup />
              </div>

              <div css={{ marginTop: '24px' }}>
                <ManagementTable
                  roles={state.roles}
                  groups={state.groups}
                  closeUsersManagement={onClose}
                  openEditGroupDialog={openEditGroupDialog}
                  closeEditGroupDialog={closeEditGroupDialog}
                  license={license}
                />
              </div>
            </Fragment>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

const UsersWrapper = props => (
  <UsersProvider>
    <UsersManagement {...props} />
  </UsersProvider>
)

export default withStyles(styles)(UsersWrapper)
