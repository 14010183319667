import React, { useState } from 'react'

const AccountsContext = React.createContext([{}, () => {}])

const AccountsProvider = props => {
  const [state, setState] = useState({
    user: [],
    crudCount: 0,
    currentPolicyIndex: null,
    page: 0,
    rowsPerPage: 25,
    order: 'asc',
    orderBy: 'name',
    expanded: [],
  })

  return (
    <AccountsContext.Provider value={[state, setState]}>{props.children}</AccountsContext.Provider>
  )
}

export { AccountsContext, AccountsProvider }
