import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
// import Divider from '@mui/material/Divider'

import { useForm, Controller } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { FETCH_MAPPINGS } from '../../Mappings'

import {
  InputLabel,
  Input,
  DialogTitle,
  DialogActions,
  DialogContent,
  ActionsDivider,
} from '@jeeves/pages/DataLabels/Custom/Dialogs/Dialogs.styles.js'

import { LearnMore } from '../components/LearnMore'

const DELETE_MAPPING = gql`
  mutation DeleteDatamapMapping($repoId: String!, $datalabelName: String!, $attribute: String!) {
    deleteDatamapMapping(repoId: $repoId, datalabelName: $datalabelName, attribute: $attribute) {
      message
    }
  }
`

export const DeleteMapping = ({ open, setOpen, repoId, attribute, labelName }) => {
  const [deleteMapping, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_MAPPING, {
      refetchQueries: [FETCH_MAPPINGS],
    })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleOnClose = () => {
    setOpen(false)
  }

  const onSubmit = async () => {
    try {
      await deleteMapping({
        variables: { repoId, datalabelName: labelName, attribute: attribute.join('.') },
      })
      handleOnClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth sx={{ radius: '12px' }}>
      <DialogTitle>Delete Mapping</DialogTitle>

      <DialogContent>
        <Stack sx={{ mt: 0.5 }}>
          <Typography variant="body2" sx={{ color: 'cyralColors.grey.400' }}>
            {' '}
            Are you sure you want to delete this field mapping?
          </Typography>
        </Stack>
      </DialogContent>
      <ActionsDivider></ActionsDivider>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={onSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
