import { gql } from '@apollo/client'

export const EditRepoHelpers_repoFragment = gql`
  fragment EditRepoHelpers_repo on Repo {
    tags
    ... on MongoDBCluster {
      clusterSpecification {
        ... on StaticNodeSpecification {
          staticNodes {
            host
            port
          }
        }
        ... on SRVRecordSpecification {
          SRVRecord
        }
      }
    }
    ... on StandaloneRepo {
      endpoint {
        host
        port
      }
    }
    ... on MongoDBReplicaSetRepo {
      replicaSetName
    }
  }
`

const getMongoDBReplicaSetRepoDefaultValues = repo => {
  const { replicaSetName, numNodes, clusterSpecification } = repo
  const connectionFormat = clusterSpecification.__typename

  return {
    replicaSetName,
    connectionFormat,
    numNodes,
    nodes: connectionFormat === 'StaticNodeSpecification' ? clusterSpecification.staticNodes : null,
    SRVRecord:
      connectionFormat === 'SRVRecordSpecification' ? clusterSpecification.SRVRecord : null,
  }
}

const getMongoDBShardedClusterRepoDefaultValues = repo => {
  const { numNodes, clusterSpecification } = repo
  const connectionFormat = clusterSpecification.__typename

  return {
    connectionFormat,
    numNodes,
    nodes: connectionFormat === 'StaticNodeSpecification' ? clusterSpecification.staticNodes : null,
    SRVRecord:
      connectionFormat === 'SRVRecordSpecification' ? clusterSpecification.SRVRecord : null,
  }
}

export const getDefaultValues = repo => {
  const sharedDefaultValues = {
    tags: repo.tags,
  }

  switch (repo.__typename) {
    case 'MongoDBShardedCluster':
      return {
        ...sharedDefaultValues,
        ...getMongoDBShardedClusterRepoDefaultValues(repo),
      }
    case 'MongoDBReplicaSetRepo':
      return {
        ...sharedDefaultValues,
        ...getMongoDBReplicaSetRepoDefaultValues(repo),
      }
    default:
      return { ...sharedDefaultValues, host: repo.endpoint.host, port: repo.endpoint.port }
  }
}
