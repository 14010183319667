export const getGeneralPostgresUri = ({ connectionInfo, redactPassword = false }) => {
  const {
    username,
    host,
    port,
    password: connectionInfoPassword,
    database: connectionInfoDatabase,
  } = connectionInfo

  const password = redactPassword
    ? getRedactedPassword(connectionInfoPassword)
    : connectionInfoPassword
  const database = connectionInfoDatabase || '<DATABASE>'

  return {
    unescaped: `postgresql://${username}:${password}@${host}:${port}/${database}?sslmode=require`,
    escaped: createEscapedConnectionString({
      scheme: 'postgresql',
      connectionInfo,
      options: {
        sslmode: 'require',
      },
    }),
  }
}

export const getPsqlCommand = ({ connectionInfo, redactPassword = false }) => {
  const {
    username,
    host,
    port,
    password: connectionInfoPassword,
    database: connectionInfoDatabase,
  } = connectionInfo

  const password = redactPassword
    ? getRedactedPassword(connectionInfoPassword)
    : connectionInfoPassword
  const database = connectionInfoDatabase || '<DATABASE>'

  return `PGPASSWORD=${password} PGSSLMODE=require psql -h ${host} -p ${port} -U ${username} -d ${database}`
}

export const getPosticoUri = ({ connectionInfo, options = {} }) => {
  const { password, ...rest } = connectionInfo
  const { showPassword = true } = options

  return createEscapedConnectionString({
    scheme: 'postico',
    connectionInfo: {
      ...rest,
      password: showPassword ? password : getRedactedPassword(password),
    },
    options: {
      sslmode: 'require',
    },
  })
}

// ====== HELPERS ======
export const getRedactedPassword = password => {
  return '*'.repeat(password.length)
}

export const getDisplayedPassword = password => {
  return password.isVisible ? password.value : getRedactedPassword(password.value)
  // return password.isVisible ? password.value : '*'.repeat(password.value.length)
}

const schemesWithoutRequiredDatabase = ['jdbc:mysql']

// Modified version of connection-string-parser lib
// https://www.npmjs.com/package/connection-string-parser
export const createEscapedConnectionString = ({ scheme, connectionInfo, options = {} }) => {
  const { username, host, ports, password, database } = connectionInfo

  let uri = `${scheme}://`

  if (username) {
    uri += encodeURIComponent(username)
    // Allow empty passwords
    if (password) {
      uri += `:${encodeURIComponent(password)}`
    }
    uri += '@'
  }

  const hosts = ports.map(port => ({
    host,
    port,
  }))

  uri += _formatAddress(hosts)
  uri += `/${
    database
      ? encodeURIComponent(database)
      : schemesWithoutRequiredDatabase.includes(scheme)
      ? ''
      : '<DATABASE>'
  }`

  if (options && Object.keys(options).length > 0) {
    uri +=
      '?' +
      Object.keys(options)
        .map(option => `${encodeURIComponent(option)}=${encodeURIComponent(options[option])}`)
        .join('&')
  }

  return uri
}

/**
 * Formats the address portion of a connection string
 */
const _formatAddress = hosts => {
  return hosts
    .map(
      address =>
        encodeURIComponent(address.host) +
        (address.port ? `:${encodeURIComponent(address.port.toString(10))}` : '')
    )
    .join(',')
}
