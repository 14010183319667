/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Formik, Form } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core'

import { Button, Dialog, FlexGrow } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import { TextField } from '@jeeves/pages/Account/Users'
import Permissions from '../Permissions'

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    marginBottom: theme.spacing.unit * 4,
  },
})

const EditUser = ({ classes, user, onClose, onUpdate, dialogOpen, roles, deleteUser, loading }) => {
  const { hasPermission } = useAuth()

  if (!user) return null

  const isSsoUser = () => {
    return user.isSSO
  }

  return (
    <Dialog open={dialogOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.title}>
        Update
        <Typography variant="caption">
          {!isSsoUser()
            ? `Update information about user with email ${user.email}`
            : `${user.email} is an SSO user. Their information can only be updated through the SSO provider. `}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            roleId: user.role ? user.role.id : '',
            id: user.id,
          }}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)
            await onUpdate(values)
            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            getFieldProps,
            isValid,
          }) => (
            <Form>
              <TextField
                label="First Name"
                disabled={isSsoUser()}
                {...getFieldProps('first_name')}
                onChange={e => setFieldValue('first_name', e.target.value.trim())}
              />
              <TextField
                label="Last Name"
                disabled={isSsoUser()}
                {...getFieldProps('last_name')}
                onChange={e => setFieldValue('last_name', e.target.value.trim())}
              />

              <TextField
                label="Email"
                {...getFieldProps('email')}
                disabled={isSsoUser()}
                onChange={e => setFieldValue('email', e.target.value.trim())}
              />

              {roles && (
                <FormControl margin="dense" fullWidth css={{ margin: '16px 0' }}>
                  <Permissions
                    roleId={values.roleId}
                    name="roleId"
                    roles={roles}
                    onChange={handleChange}
                  />
                </FormControl>
              )}

              <DialogActions
                css={() => css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Button
                  onClick={deleteUser}
                  color="secondary"
                  disabled={!hasPermission('users:delete')}
                >
                  Delete User
                </Button>

                <FlexGrow />

                <Button onClick={onClose} color="primary" css={{ marginRight: '8px' }}>
                  Cancel
                </Button>

                <div css={{ position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !isValid}
                  >
                    Save Changes
                  </Button>
                  {isSubmitting && <ButtonLoading />}
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(EditUser)
