/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { BlueLogo } from '@jeeves/components/Logo'
import { Button, CenteredText, SetupLayout, Subtitle, Title } from '@jeeves/pages/Setup/components'
import { useAuth } from '@jeeves/hooks'

export const Welcome = ({ onNext }) => {
  const { user } = useAuth()
  const name = [user.given_name, user.family_name].join(' ')

  return (
    <SetupLayout
      onNext={onNext}
      actionButton={<Button onClick={onNext}>Start</Button>}
      absoluteIndex={true}
    >
      <BlueLogo
        css={() => css`
          margin: 0 auto 24px auto;
          width: 128px;
        `}
      />
      <Title>{name ? `Welcome, ${name}!` : 'Welcome!'}</Title>
      <Subtitle>Access control and visibility across all your data endpoints</Subtitle>
      <CenteredText
        css={() => css`
          font-style: italic;
          margin-top: 48px;
          margin-bottom: -24px;
        `}
      >
        We’ll make this simple and get you going in less than 10 minutes
      </CenteredText>
    </SetupLayout>
  )
}
