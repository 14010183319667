/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useContext, useEffect, useRef, useState, Fragment } from 'react'
import Confetti from 'react-confetti'
import { Redirect } from 'react-router-dom'

import useUsers from '@jeeves/pages/Account/UsersManagement/hooks/useUsers'
import { useMount } from '@jeeves/hooks'
import {
  UsersProvider,
  UsersContext,
} from '@jeeves/pages/Account/UsersManagement/contexts/UsersContext'

import { Welcome, ConfigureSlack, AddUsers, Sandbox, SandboxIdp, Finish } from './steps'
import { SetupContext, SetupProvider } from './SetupContext'
import { CircleProgress, SimpleDashboard } from '@jeeves/pages/Setup/components'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import { usePopup } from '@jeeves/hooks'

const SetupPageInner = () => {
  // eslint-disable-next-line
  const { setupStep, setSetupStep, wizardComplete, isRegistrant, Steps, users, setUsers } =
    useContext(SetupContext)
  // eslint-disable-next-line
  const [usersState, setUsersState] = useContext(UsersContext)
  const { refreshGroups } = useUsers()
  const { groups } = usersState
  const screenSizeContainer = useRef(null)
  const dynamicContainer = useRef(null)
  const [dotsAbsolute, setDotsAbsolute] = useState(true)
  const [license, setLicense] = useState(null)
  const [sidecarHost, setSidecarHost] = useState(null)
  const [slackConnected, setSlackConnected] = useState(false)

  const { showError, showSuccess } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const STARTED_WIZARD = {
    subtype: 'setup_started',
    subject: 'Registrant started setup wizard',
  }

  const COMPLETED_WIZARD = {
    subtype: 'setup_completed',
    subject: 'Registrant completed setup wizard, accessed management console',
  }

  const postSlackInvite = async () => {
    try {
      const resp = await ec.post('/wizard/slackInvite', {
        user_email: user.email,
        user_first_name: user.given_name,
        cp_link: window.location.href,
      })
      // console.log('postSlackInvite: ', resp)
      setSlackConnected(true)
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.message.includes('already_in_team')
      ) {
        showError(`You've already been invited to our Slack channel. Please check your email.`)
      }
      showError('Something went wrong. Please try again!')
      console.error(e)
    }
  }

  const fetchLicense = async () => {
    try {
      const license = await ec.get('/entitlements/licenses').then(res => res.data)
      // console.log('plans.fetchLicense: ', license)
      setLicense(license)
      return license
    } catch (e) {
      console.error(e)
    }
  }

  // TODO: need to remove hard coded free trial
  const postNotification = async status => {
    if (!license) {
      return
    }
    const notificationBody = {
      type: 'Customer',
      subtype: status['subtype'],
      subject: status['subject'],
      companyName: license.customer.name,
      contactName: user.given_name,
      currentPlan: license.userFriendlyName,
      customerEmail: user.email,
    }
    try {
      await ec.post('/integrations/_sendNotification', notificationBody)
    } catch (e) {
      console.error(e)
    }
  }

  //commented out because sandbox page gone for now
  // const fetchSidecarHost = async () => {
  //   try {
  //     const sidecars = await ec.get('/sidecars/home').then(res => res.data)

  //     for (let sidecar of sidecars) {
  //       if (
  //         sidecar.sidecar &&
  //         sidecar.sidecar.labels &&
  //         sidecar.sidecar.labels.length > 0 &&
  //         sidecar.sidecar.labels.includes('_cyral-sandbox')
  //       ) {
  //         setSidecarHost(sidecar.sidecar.sidecarEndpoint)
  //       }
  //     }
  //   } catch (e) {}
  // }

  useEffect(() => {
    if (license) {
      postNotification(STARTED_WIZARD)
    }
  }, [license])

  useMount(() => {
    // fetchSidecarHost()
    fetchLicense()
    refreshGroups()
    // postSlackInvite()
  })

  const changeStep = () => {
    setSetupStep(prevStep => prevStep + 1)
  }

  const groupNames = groups
    .map(({ name, id, description }) => ({ name, id, description }))
    .filter(group => group.name.toLowerCase() !== 'db access')

  const centeredAndAbsolute = setupStep !== Steps.Sandbox && setupStep !== Steps.SandboxIdp

  const handleDotsAbsolute = () => {
    if (screenSizeContainer.current && dynamicContainer.current) {
      setDotsAbsolute(
        screenSizeContainer.current.clientHeight > dynamicContainer.current.clientHeight
      )
    }
  }

  // TO DO: not a good way to do this, should use minheight solution
  useEffect(() => {
    handleDotsAbsolute()
    const resizeListener = () => handleDotsAbsolute()
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [screenSizeContainer, dynamicContainer, setupStep])

  if (wizardComplete === null || isRegistrant === null) {
    return null
  }

  return (
    <Fragment>
      {(wizardComplete || !isRegistrant) && <Redirect to="/home"></Redirect>}
      <SimpleDashboard centered={centeredAndAbsolute} ref={screenSizeContainer}>
        <div
          ref={dynamicContainer}
          css={{
            position: dotsAbsolute ? 'static' : 'relative',
            display: 'block',
            paddingBottom: '80px',
            minWidth: '100vw',
          }}
        >
          <div css={{ position: 'static', minWidth: '900px' }}>
            {setupStep === Steps.Finish && (
              <div
                css={() => css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 0;
                  right: 0;
                `}
              >
                <Confetti recycle={false} numberOfPieces={400} gravity={0.25} />
              </div>
            )}

            {setupStep === Steps.Welcome && <Welcome onNext={changeStep} />}
            {setupStep === Steps.ConfigureSlack && (
              <ConfigureSlack
                slackConnected={slackConnected}
                postSlackInvite={postSlackInvite}
                onNext={changeStep}
              />
            )}
            {setupStep === Steps.AddUsers && (
              <AddUsers users={users} setUsers={setUsers} groups={groupNames} onNext={changeStep} />
            )}
            {/* {setupStep === Steps.Sandbox && (
              <Sandbox sidecarHost={sidecarHost} onNext={changeStep} />
            )}
            {setupStep === Steps.SandboxIdp && (
              <SandboxIdp sidecarHost={sidecarHost} token={token} onNext={changeStep} />
            )} */}
            {setupStep >= Steps.Finish && (
              <Finish
                postNotification={postNotification}
                COMPLETED_WIZARD={COMPLETED_WIZARD}
                license={license}
              />
            )}
            <div>
              <CircleProgress absoluteIndex={true} />
            </div>
          </div>
        </div>
      </SimpleDashboard>
    </Fragment>
  )
}

export const SetupPage = () => (
  <UsersProvider>
    <SetupPageInner />
  </UsersProvider>
)
