const allTabs = [
  {
    label: 'Overview',
    value: 'overview',
  },
  {
    label: 'Data Map',
    value: 'datamap',
  },
  {
    label: 'User Authentication',
    value: 'user-authentication',
  },
  {
    label: 'Approvals',
    value: 'approvals',
  },
  {
    label: 'Apps & BI Tools',
    value: 'apps-and-bi-tools',
  },
  {
    label: 'Policies',
    value: 'policies',
  },
  {
    label: 'Network Shield',
    value: 'network-shield',
  },
  {
    label: 'Audit Report',
    value: 'audit-report',
  },
  {
    label: 'Config',
    value: 'config',
  },
]

const repoTabsMap = {
  rest: ['datamap'],
  postgresql: [
    'datamap',
    'user-authentication',
    'approvals',
    'apps-and-bi-tools',
    'policies',
    'audit-report',
    'config',
  ],
  redshift: [
    'datamap',
    'user-authentication',
    'approvals',
    'apps-and-bi-tools',
    'policies',
    'audit-report',
    'config',
  ],
  mariadb: ['datamap', 'user-authentication', 'approvals', 'policies', 'audit-report', 'config'],
  oracle: [
    'overview',
    'datamap',
    'user-authentication',
    'approvals',
    'network-shield',
    'policies',
    'audit-report',
    'config',
  ],
  sqlserver: [
    'overview',
    'datamap',
    'user-authentication',
    'approvals',
    'apps-and-bi-tools',
    'policies',
    'network-shield',
    'audit-report',
    'config',
  ],
  denodo: [
    'datamap',
    'user-authentication',
    'approvals',
    'apps-and-bi-tools',
    'policies',
    'audit-report',
    'config',
  ],
  dremio: ['user-authentication', 'approvals', 'datamap', 'audit-report', 'config'],
  dynamodb: ['datamap', 'audit-report', 'config'],
  dynamodbstreams: ['audit-report', 'config'],
  galera: ['datamap', 'user-authentication', 'approvals', 'policies', 'audit-report', 'config'],
  mysql: [
    'datamap',
    'user-authentication',
    'approvals',
    'apps-and-bi-tools',
    'policies',
    'audit-report',
    'config',
  ],
  s3: ['datamap', 'user-authentication', 'approvals', 'policies', 'audit-report', 'config'],
  snowflake: ['datamap', 'apps-and-bi-tools', 'policies', 'audit-report', 'config'],
  mongodb: ['datamap', 'user-authentication', 'approvals', 'policies', 'audit-report', 'config'],
}

const getTabs = repoType => {
  const repoTabs = repoTabsMap[repoType]

  return allTabs.filter(tab => repoTabs?.includes(tab.value))
}

export default getTabs
