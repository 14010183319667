import * as React from 'react'
import Box from '@mui/material/Box'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { MappingCard } from './MappingCard/MappingCard'
import { PendingRecommendations } from './Recommendations/PendingRecommendation/PendingRecommendations'
import { AddMapping } from './Dialogs'
import { useQuery, gql } from '@apollo/client'

export const FETCH_RECOMMENDATIONS = gql`
  query RepoDatamapRecommendations($repoId: String!) {
    repo(id: $repoId) {
      id
      datamapRecommendations {
        id
        attribute
        datalabel {
          name
        }
        status
        source
        createdAt
        updatedAt
      }
    }
  }
`

const FETCH_LABELS = gql`
  query LabelsQuery {
    datalabels {
      name
    }
  }
`

export const FETCH_MAPPINGS = gql`
  query DatamapMappingsForRepo($repoId: String!) {
    repo(id: $repoId) {
      id
      mappedDatalabelsRelationship {
        edges {
          node {
            name
            description
            type
            classificationRule {
              ruleType
              ruleCode
            }
            tags
          }
          datamapMappings {
            field {
              ... on Attribute {
                attribute
              }
              ... on RESTEndpoint {
                endpoint {
                  uri
                  method
                  createdCount
                  readCount
                  updatedCount
                  deletedCount
                }
              }
            }
          }
        }
      }
    }
  }
`

export const Mappings = ({ repoId, repoType }) => {
  const { loading, error, data } = useQuery(FETCH_MAPPINGS, {
    variables: {
      repoId: repoId,
    },
  })

  const { loading: labelsLoading, error: labelsError, data: labelsData } = useQuery(FETCH_LABELS)
  const {
    loading: recommendationsLoading,
    error: recommendationsError,
    data: recommendationsData,
  } = useQuery(FETCH_RECOMMENDATIONS, {
    variables: {
      repoId: repoId,
    },
  })

  return (
    <Box sx={{ px: 8, pt: 4 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h3" sx={{ color: 'cyralColors.grey.500', mb: 4 }}>
          Mappings
        </Typography>
        {labelsData && (
          <AddMapping
            repoType={repoType}
            repoId={repoId}
            datalabels={labelsData.datalabels}
          ></AddMapping>
        )}
      </Stack>

      {!recommendationsError && recommendationsData?.repo?.datamapRecommendations?.length > 0 && (
        <PendingRecommendations
          repoId={repoId}
          repoType={repoType}
          recommendations={recommendationsData.repo.datamapRecommendations}
          recommendationStatus="RECOMMENDED"
        ></PendingRecommendations>
      )}

      {data &&
        data.repo?.mappedDatalabelsRelationship?.edges.map(mapping => (
          <MappingCard
            key={mapping.node.name}
            repoId={repoId}
            mapping={mapping}
            repoType={repoType}
            datalabels={labelsData && labelsData.datalabels}
          ></MappingCard>
        ))}
    </Box>
  )
}
