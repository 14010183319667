import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
} from '@jeeves/new-components'

import {
  Stack,
  Typography,
  DialogTitle,
  DialogContent,
  Divider,
} from '@mui/material'

import {
  DialogActions,
} from '@jeeves/new-components/Dialog'

export const RequestAccessFeedbackDialog = ({ open, handleOnClose = () => {} }) => {

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth>
      <DialogTitle>
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Success
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ color: 'text.secondary' }}>
          Your access request was successfully submitted.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOnClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
