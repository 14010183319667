import React, { useState, useEffect } from 'react'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

// TO DO: find better way to do is/not registrant steps

const IS_REGISTRANT_STEPS = {
  Welcome: 1,
  ConfigureSlack: 2,
  AddUsers: 3,
  // Sandbox: 4,
  // SandboxIdp: 5,
  Finish: 4,
}

const SetupContext = React.createContext([{}, () => {}])

const SetupProvider = ({ children }) => {
  const { getTokenSilently, getTokenWithPopup, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [wizardComplete, setWizardComplete] = useState(null)
  const [isRegistrant, setIsRegistrant] = useState(null)
  const [users, setUsers] = useState([])
  const [setupStep, setSetupStep] = useState(1)
  const [Steps, setSteps] = useState({})

  const fetchWizardState = async () => {
    try {
      if (user) {
        const wizardResponse = await ec.get(`/wizard/${user.sub}`).then(res => res.data)

        setWizardComplete(wizardResponse.wizardComplete)
        setIsRegistrant(wizardResponse.isRegistrant)

        setSteps(IS_REGISTRANT_STEPS)
      }
    } catch (e) {
      setWizardComplete(undefined)
      setIsRegistrant(undefined)
      console.log('plans.fetchWizardComplete error: ', e)
    }
  }

  const completeWizard = async () => {
    try {
      await ec.post('/wizard/wizardComplete')
      await fetchWizardState()
    } catch (e) {
      console.log('plans.completeWizard error: ', e)
    }
  }

  const fetchUsers = async () => {
    try {
      const users = await ec.get('/users')
      setUsers(users.data)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchWizardState()
    fetchUsers()
  }, [])

  return (
    <SetupContext.Provider
      value={{
        setupStep,
        setSetupStep,
        wizardComplete,
        isRegistrant,
        Steps,
        completeWizard,
        users,
        setUsers,
      }}
    >
      {children}
    </SetupContext.Provider>
  )
}

export { SetupProvider, SetupContext }
