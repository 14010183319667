import { FormProvider } from 'react-hook-form'
import { Box, FormControl, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Button, InputLabel } from '@jeeves/new-components'

import { useLogging } from './useLogging'
import LogsSelect from './components/LogsSelect'

export const Logging_queryFragment = graphql(`
  fragment Logging_query on Query {
    ...useLogging_query
    ...LogsSelect_query
  }
`)

interface LoggingProps {
  query: FragmentType<typeof Logging_queryFragment>
}

const Logging = ({ query: queryProp }: LoggingProps) => {
  const query = useFragment(Logging_queryFragment, queryProp)

  const { loading, methods, onSubmit } = useLogging({ query })

  const {
    formState: { isDirty },
    reset,
  } = methods

  const isDisabled = loading || !isDirty

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          border: 1,
          borderColor: 'cyralColors.grey.200',
          p: 4,
          borderRadius: theme => theme.radii.base,
        }}
      >
        <Stack spacing={6}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Logging
          </Typography>

          <Stack spacing={4} sx={{ width: '50%' }}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="dataActivityLogsIntegrationId">Data Activity Logs</InputLabel>

              <LogsSelect
                name="dataActivityLogsIntegrationId"
                id="dataActivityLogsIntegrationId"
                query={query}
              />
            </FormControl>

            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="diagnosticLogsIntegrationId">Diagnostic Logs</InputLabel>

              <LogsSelect
                name="diagnosticLogsIntegrationId"
                id="diagnosticLogsIntegrationId"
                query={query}
              />
            </FormControl>
          </Stack>

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
            <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" disabled={isDisabled} loading={loading}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default Logging
