/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ChevronRight } from '@material-ui/icons'
import { Button } from '.'

export const NextButton = ({ onClick, ...props }) => (
  <Button onClick={onClick} {...props}>
    Continue <ChevronRight />
  </Button>
)
