import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@apollo/client'

import { graphql } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const CREATE_SIDECAR = graphql(`
  mutation CreateSidecar($name: String!) {
    createSidecar(name: $name) {
      sidecar {
        id
      }
    }
  }
`)

const createSidecarSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a name.' }),
})

type CreateSidecarSchema = z.infer<typeof createSidecarSchema>

export const useCreateSidecarModal = () => {
  const history = useHistory()

  const { toast } = useToast()

  const methods = useForm<CreateSidecarSchema>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(createSidecarSchema),
  })

  const [createSidecar, { loading }] = useMutation(CREATE_SIDECAR, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while creating the sidecar, please try again',
      })
    },
    onCompleted: data => {
      history.push(`/sidecars/${data.createSidecar?.sidecar?.id}`)
    },
  })

  const onSubmit = async ({ name }: CreateSidecarSchema) => {
    await createSidecar({
      variables: {
        name,
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
