import { FormControlLabel, Switch, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { LearnMore } from '../../LearnMore'
import { AuthenticationFormSchema } from '../helpers/schema'

const AllowNativeAuthenticationSwitch = () => {
  const { control } = useFormContext<AuthenticationFormSchema>()

  return (
    <Controller
      name="allowNativeAuthentication"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <FormControlLabel
          control={<Switch name={name} onChange={onChange} checked={value} />}
          label={
            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              Allow native authentication for this database.{' '}
              <LearnMore docsPath="/manage-repositories/repo-advanced-settings/#authentication" />
            </Typography>
          }
        />
      )}
    />
  )
}

export default AllowNativeAuthenticationSwitch
