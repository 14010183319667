import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { ChromeReaderModeTwoTone, SupportAgentTwoTone } from '@mui/icons-material'

import { getDocsURL } from '@jeeves/utils/docsURL'
import { v5Theme } from '@jeeves/theme'

import { AllowCyralAccess, HelpAndSupportCard, HelpAndSupportCardIcon } from './components'
import { HelpAndSupportCardProps } from './components/HelpAndSupportCard'

const helpAndSupportCards: HelpAndSupportCardProps[] = [
  {
    helperText: 'A comprehensive guide for using Cyral',
    href: getDocsURL(),
    icon: (
      <HelpAndSupportCardIcon>
        <ChromeReaderModeTwoTone fontSize="inherit" />
      </HelpAndSupportCardIcon>
    ),
    title: 'Read the docs',
  },
  {
    helperText: 'Submit a question or report an issue to our support team',
    href: 'https://support.cyral.com/support/tickets/new',
    icon: (
      <HelpAndSupportCardIcon>
        <SupportAgentTwoTone fontSize="inherit" />
      </HelpAndSupportCardIcon>
    ),
    title: 'Submit a ticket',
  },
]

const Help = () => {
  return (
    <ThemeProvider theme={v5Theme}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack spacing={4} sx={{ width: '860px', alignItems: 'center' }}>
          <Typography variant="h2">We're here to help!</Typography>

          <Stack direction="row" spacing={10}>
            {helpAndSupportCards.map(card => (
              <HelpAndSupportCard key={card.title} {...card} />
            ))}
          </Stack>

          <AllowCyralAccess />
        </Stack>
      </Box>
    </ThemeProvider>
  )
}

export default Help
