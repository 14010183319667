/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Redirect } from 'react-router-dom'

import { useAppConfig } from '@jeeves/hooks'
import useTotalsHook from './useTotalsHook'

const Home = () => {
  const { config } = useAppConfig()
  const { wizardComplete, isRegistrant } = useTotalsHook()

  if (!config || wizardComplete === null || isRegistrant === null) {
    return null
  }

  if (!wizardComplete && isRegistrant) {
    return <Redirect to="/setup" />
  }

  return <Redirect to="/overview" />
}

export default Home
