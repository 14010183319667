import { useAuth } from '../components/Auth'
import ExpressClient from '../clients/express'
import { entitlementPlans } from './constants'
import axios from 'axios'

const useConfig = setAppConfig => {
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  /* HELPER FUNCTIONS */

  const isEmptyObj = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  const prependPrefix = (baseString, prefix) => {
    if (typeof baseString !== 'string' || !baseString || !prefix) return ''
    if (baseString.substr(0, prefix.length) !== prefix) {
      return prefix + baseString
    }
    return baseString
  }

  const setFullFeaturesLicense = async () => {
    const AAAplan = await getAAAPlan()
    setAppConfig(state => ({ ...state, license: { ...AAAplan, isFreeTrial: false } }))
  }

  /* ENTITLEMENTS & LICENSES FUNCTIONS */

  // Fetches hardcoded AAA plan for when we want to grant full license in the case of no license existing in entitlements service.
  const getAAAPlan = async () => {
    try {
      return JSON.parse(entitlementPlans.AAA)
    } catch (e) {
      console.error(e)
    }
  }

  const hasSandbox = async () => {
    try {
      const repos = await ec.get('/repos/unaltered').then(res => res.data)
      // const sandboxRepo = repos.find(repo => repo.repo?.labels?.includes('_cyral-sandbox'))
      return repos.some(repo => repo.repo?.labels?.includes('_cyral-sandbox'))
    } catch (e) {}
  }

  // Fetches licenses from entitlements service and sets it to global app context
  // If no license is return from backend, we grant the UI a license with full feature set
  const setLicense = async () => {
    try {
      const license = await ec.get('/entitlements/licenses').then(res => res.data)
      if (isEmptyObj(license)) {
        setFullFeaturesLicense()
        return
      }
      setAppConfig(state => ({ ...state, license }))
    } catch (e) {
      console.error(e.response)
      setFullFeaturesLicense()
    }
  }

  const setConfig = async () => {
    try {
      const configData = await ec.get('/api/config').then(result => result.data)
      const containsSandbox = await hasSandbox()

      const config = {
        ...configData,
        hasSandbox: containsSandbox,
      }

      setAppConfig(state => ({ ...state, config }))
    } catch (e) {
      console.error(e)
    }
  }

  return {
    setConfig,
    setLicense,
  }
}

export default useConfig
