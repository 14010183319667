import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { z } from 'zod'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const useLogging_queryFragment = graphql(`
  fragment useLogging_query on Query {
    sidecar(id: $sidecarId) {
      id
      loggingIntegrations {
        dataActivityLogsIntegration {
          id
          name
        }
        diagnosticLogsIntegration {
          id
          name
        }
      }
    }
  }
`)

const UPDATE_SIDECAR_LOGGING_INTEGRATIONS = graphql(`
  mutation UpdateSidecarLoggingIntegrations(
    $sidecarId: ID!
    $input: UpdateSidecarLoggingIntegrationsInput!
  ) {
    updateSidecarLoggingIntegrations(sidecarId: $sidecarId, input: $input) {
      sidecar {
        id
        loggingIntegrations {
          dataActivityLogsIntegration {
            id
            name
          }
          diagnosticLogsIntegration {
            id
            name
          }
        }
      }
    }
  }
`)

const schema = z.object({
  dataActivityLogsIntegrationId: z.string(),
  diagnosticLogsIntegrationId: z.string(),
})

type LoggingFormSchema = z.infer<typeof schema>

interface useLoggingProps {
  query: FragmentType<typeof useLogging_queryFragment>
}

export const useLogging = ({ query: queryProp }: useLoggingProps) => {
  const { id: sidecarId } = useParams<{ id: string }>()

  const { toast } = useToast()

  const { sidecar } = useFragment(useLogging_queryFragment, queryProp)

  const methods = useForm<LoggingFormSchema>({
    defaultValues: {
      dataActivityLogsIntegrationId:
        sidecar.loggingIntegrations.dataActivityLogsIntegration?.id ?? 'none',
      diagnosticLogsIntegrationId:
        sidecar.loggingIntegrations.diagnosticLogsIntegration?.id ?? 'none',
    },
  })

  const [updateSidecarLoggingIntegration, { loading }] = useMutation(
    UPDATE_SIDECAR_LOGGING_INTEGRATIONS,
    {
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) || 'An error occurred while saving, please try again.',
        })
      },
      onCompleted: () => {
        methods.reset(methods.getValues())
      },
    }
  )

  const onSubmit = async ({
    dataActivityLogsIntegrationId,
    diagnosticLogsIntegrationId,
  }: LoggingFormSchema) => {
    await updateSidecarLoggingIntegration({
      variables: {
        sidecarId,
        input: {
          dataActivityLogsIntegrationId:
            dataActivityLogsIntegrationId !== 'none' ? dataActivityLogsIntegrationId : null,
          diagnosticLogsIntegrationId:
            diagnosticLogsIntegrationId !== 'none' ? diagnosticLogsIntegrationId : null,
        },
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
