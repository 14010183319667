/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button as DefaultButton } from '@jeeves/components/Primitives'

export const Button = props => (
  <DefaultButton
    variant="contained"
    color="primary"
    {...props}
    css={() => css`
      font-size: 1.35rem;
      border-radius: 100px;
      padding: 8px 24px;
    `}
  />
)
