import { useState } from 'react'
import { useMount, usePopup } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

const setCache = obj => {
  window.localStorage.setItem('dashboard_cache', JSON.stringify(obj))
  window.localStorage.setItem('dashboard_cache_time', Math.floor(new Date().getTime() / 1000))
}

const getCache = () => {
  const objCache = JSON.parse(window.localStorage.getItem('dashboard_cache'))

  return objCache
}

const isCacheValid = () => {
  const cacheTime = window.localStorage.getItem('dashboard_cache_time')

  if (!cacheTime) {
    return false
  }

  const currentTime = Math.floor(new Date().getTime() / 1000)
  const FIVE_MINUTES = 60 * 5

  return currentTime - parseInt(cacheTime) < FIVE_MINUTES
}

export default () => {
  const [totalsLoading, setTotalsLoading] = useState(false)
  const [totals, setTotals] = useState({})
  const [stepperContent, setStepperContent] = useState(null)
  const [updatesContent, setUpdatesContent] = useState(null)
  const [marketingMedia, setMarketingMedia] = useState(null)
  const [wizardComplete, setWizardComplete] = useState(null) //TODO: refactor
  const [isRegistrant, setIsRegistrant] = useState(null) //TODO: refactor
  const { showError } = usePopup()

  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const fetchTotals = async () => {
    setTotalsLoading(true)

    try {
      const response = await Promise.all([
        ec.get('/repos/home'),
        ec.get('/sidecars/home'),
        ec.get('/integrations/identitiesCount'),
      ])

      const responseTotals = Object.values(response).map(response => response.data.length)

      const data = {
        repositories: responseTotals[0],
        sidecars: responseTotals[1],
        identities: response[2] && response[2].data,
        // identities: 1,
      }

      setTotals(data)

      setTotalsLoading(false)
    } catch (e) {
      showError(
        'There was an error while fetching the data. Please try again later or contact support if the problem persists.'
      )
    } finally {
      setTotalsLoading(false)
    }
  }

  const fetchStepperContent = async () => {
    try {
      const resp = await ec
        .get('/templates/_stepperContent')
        .then(res => res.data)
        .then(data => data.steps)
      setStepperContent(resp)
    } catch (e) {}
  }

  const fetchUpdatesContent = async () => {
    try {
      const resp = await ec
        .get('/templates/_updatesContent')
        .then(res => res.data)
        .then(data => data.updates)
      setUpdatesContent(resp)
    } catch (e) {
      console.log('/templates/_updatesContent failed 2', e)
    }
  }

  const fetchMarketingMedia = async () => {
    try {
      const resp = await ec.get('/templates/_marketingMedia').then(res => res.data)

      setMarketingMedia(resp)
    } catch (e) {}
  }

  // TODO: move this elsewhere
  const fetchWizardState = async () => {
    try {
      if (user) {
        const wizardResponse = await ec.get(`/wizard/${user.sub}`).then(res => res.data)

        setWizardComplete(wizardResponse.wizardComplete)
        setIsRegistrant(wizardResponse.isRegistrant)
        // setSteps(wizardResponse.isRegistrant ? IS_REGISTRANT_STEPS : NOT_REGISTRANT_STEPS)
      }
    } catch (e) {
      setWizardComplete(undefined)
      setIsRegistrant(undefined)
      console.log('plans.fetchWizardComplete error: ', e)
    }
  }

  useMount(() => {
    fetchTotals()
    fetchStepperContent()
    fetchMarketingMedia()
    fetchWizardState()
    fetchUpdatesContent()

    // TODO: refactor
  })

  return {
    totalsLoading,
    totals,
    stepperContent,
    marketingMedia,
    wizardComplete,
    isRegistrant,
    updatesContent,
  }
}
