import Slack from './Slack'
import SlackBot from './SlackBot'
import Teams from './Teams'
import Okta from './Okta'
import HashiCorpVault from './HashiCorpVault'
import Hive from './Hive'
import AzureAD from './AzureAD'
import GSuite from './GSuite'
import Pagerduty from './Pagerduty'
import Forgerock from './Forgerock'
import ADFS from './ADFS'
import PingOne from './PingOne'
import GenericSAML from './GenericSAML'
import Duo from './Duo'
import Logging from './Logging'
import AwsIam from './AwsIam'

export const rawServices = [
  Slack,
  SlackBot,
  Teams,
  Okta,
  HashiCorpVault,
  Hive,
  AzureAD,
  GSuite,
  Pagerduty,
  Forgerock,
  ADFS,
  PingOne,
  GenericSAML,
  Duo,
  Logging,
  AwsIam,
]

// These services are intended to be hidden from Free Trial customers
const nonFreeTrialServices = [Hive, AzureAD, ADFS]

const enabledServices = rawServices.filter(service => service.metadata.enabled)

export const Services = enabledServices

export const FreeTrialServices = enabledServices.filter(service => {
  const nonFTServiceNames = nonFreeTrialServices.map(service => service.metadata.name)
  return !nonFTServiceNames.includes(service.metadata.name)
})
