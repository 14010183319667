import { NavLink } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import DescriptionIcon from '@material-ui/icons/Description'
import MapIcon from '@material-ui/icons/Map'

import { useAuth, useAppConfig } from '@jeeves/hooks'
import { MenuItem } from './components/MenuItem'

const ThirdSection = ({ iconOnly = false }) => {
  const { hasPermission } = useAuth()
  const { config, license } = useAppConfig()

  const allowPolicy = license?.features?.some(f => f.internalName === 'AllowPolicyMonitoring')

  return (
    <MenuList>
      {config?.enableDatamap && allowPolicy && (
        <MenuItem
          component={NavLink}
          to="/DataLabels"
          disabled={!hasPermission('datamap:read')}
          iconOnly={iconOnly}
          primaryText="Data Labels"
          listItemIcon={<MapIcon fontSize="small" />}
        />
      )}

      {allowPolicy && (
        <MenuItem
          component={NavLink}
          to="/policies"
          disabled={!hasPermission('policies:read')}
          iconOnly={iconOnly}
          primaryText="Global Policies"
          listItemIcon={<DescriptionIcon fontSize="small" />}
        />
      )}
    </MenuList>
  )
}

export default ThirdSection
