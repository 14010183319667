import React from 'react'
import { NavLink } from 'react-router-dom'

import { MenuList } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

import { useAuth } from '../Auth'
import { MenuItem } from './components/MenuItem'

const FifthSection = ({ iconOnly }) => {
  const { hasPermission } = useAuth()

  return (
    <MenuList>
      {(hasPermission('admin:profile:read') || hasPermission('profile:read')) && (
        <MenuItem
          component={NavLink}
          to="/admin/profile"
          iconOnly={iconOnly}
          primaryText="Profile"
          listItemIcon={<SettingsIcon fontSize="small" />}
        />
      )}

      {(hasPermission('admin:etcd:read') || hasPermission('etcd:read')) && (
        <MenuItem
          component={NavLink}
          to="/admin/etcd"
          iconOnly={iconOnly}
          primaryText="etcd Viewer"
          listItemIcon={<SettingsIcon fontSize="small" />}
        />
      )}

      <MenuItem
        component={NavLink}
        to="/setup"
        iconOnly={iconOnly}
        primaryText="Setup Flow"
        listItemIcon={<SettingsIcon fontSize="small" />}
      />
    </MenuList>
  )
}

export default FifthSection
