import { FormProvider } from 'react-hook-form'
import { Alert, Stack, Typography } from '@mui/material'

import { Button } from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import { AllowNativeAuthenticationSwitch, AuthenticationMethodRadioGroup } from './components'
import { useAuthentication } from './hooks/useAuthentication'

const Authentication_QueryFragment = graphql(`
  fragment Authentication_Query on Query {
    ...useAuthentication_Query
    ...AuthenticationMethodRadioGroup_query
  }
`)

interface AuthenticationProps {
  query: FragmentType<typeof Authentication_QueryFragment>
}

const Authentication = ({ query: queryProp }: AuthenticationProps) => {
  const query = useFragment(Authentication_QueryFragment, queryProp)

  const { loading, methods, onSubmit } = useAuthentication({ query })

  const {
    reset,
    formState: { isDirty },
    watch,
  } = methods

  const isDisabled = loading || !isDirty

  const values = watch()

  const { allowNativeAuthentication, authenticationMethodType } = values

  const isIdentityProviderIdSelected =
    authenticationMethodType === 'identityProvider' && Boolean(values.identityProviderId)

  const isAwsIamIntegrationSelected =
    authenticationMethodType === 'awsIamIntegration' && Boolean(values.awsIamIntegrationId)

  const shouldRenderAlert =
    !allowNativeAuthentication && !isIdentityProviderIdSelected && !isAwsIamIntegrationSelected

  return (
    <FormProvider {...methods}>
      <Stack spacing={6} component="form" onSubmit={onSubmit}>
        {shouldRenderAlert && (
          <Alert
            severity="info"
            sx={{
              color: 'cyralColors.yellow.500',
              backgroundColor: 'cyralColors.yellow.100',
              '& .MuiAlert-icon': {
                color: 'inherit',
              },
            }}
          >
            <Typography variant="h6" sx={{ color: 'cyralColors.yellow.500' }}>
              This configuration will cause this data repository to be inaccessible through the
              sidecar.
            </Typography>
          </Alert>
        )}

        <Stack spacing={4}>
          <AllowNativeAuthenticationSwitch />
          <AuthenticationMethodRadioGroup query={query} />
        </Stack>

        <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
          <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
            Cancel
          </Button>
          <Button type="submit" disabled={isDisabled} loading={loading}>
            Save
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default Authentication
