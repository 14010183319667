/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Formik, Form } from 'formik'
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core'
import { get } from 'lodash'
import * as yup from 'yup'

import { useAuth } from '@jeeves/components/Auth'
import { ButtonLoading } from '@jeeves/components/Loading'
import { ActionButton, Button, Dialog } from '@jeeves/components/Primitives'
import Permissions from '../Permissions'
import { TextField } from '@jeeves/pages/Account/Users'

const AddUser = ({ roles, onClose, onSubmit, dialogOpen, loading, onOpen, disabled }) => {
  const { hasPermission } = useAuth()
  // TODO: roles[2] stores the 'user' role which we want as default.
  // create a better mapping for default roles later on
  const USER_ROLE = get(roles, '0.id')

  if (disabled) {
    return <div></div>
  } else {
    return (
      <Fragment>
        <ActionButton onClick={onOpen} disabled={!hasPermission('users:create')} />

        {roles.length > 0 && (
          <Dialog open={dialogOpen} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" css={{ padding: '24px' }}>
              Add user
              <Typography variant="caption">
                Please add information about new user and choose appropriate permission level for
                the role
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  roleId: USER_ROLE,
                }}
                validationSchema={yup.object({
                  first_name: yup.string().required('Please enter a first name'),
                  last_name: yup.string().required('Please enter a last name'),
                  email: yup
                    .string()
                    .email()
                    .required('Please enter a valid email address'),
                  roleId: yup.string().required(),
                })}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  await onSubmit(values)
                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  getFieldProps,
                  isValid,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <TextField
                      label="First Name"
                      name="first_name"
                      {...getFieldProps('first_name')}
                      error={errors.first_name && touched.first_name && true}
                      helperText={errors.first_name && touched.first_name ? errors.first_name : ''}
                    />
                    <TextField
                      label="Last Name"
                      name="last_name"
                      {...getFieldProps('last_name')}
                      error={errors.last_name && touched.last_name && true}
                      helperText={errors.last_name && touched.last_name ? errors.last_name : ''}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      {...getFieldProps('email')}
                      error={errors.email && touched.email && true}
                      helperText={errors.email && touched.email ? errors.email : ''}
                    />
                    <FormControl margin="dense" fullWidth css={{ margin: '16px 0' }}>
                      <Permissions
                        roleId={values.roleId}
                        name="roleId"
                        roles={roles}
                        onChange={handleChange}
                      />
                    </FormControl>

                    <DialogActions>
                      <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                        Cancel
                      </Button>
                      <div css={{ position: 'relative' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          disabled={isSubmitting || !isValid}
                        >
                          Add User
                        </Button>
                        {isSubmitting && <ButtonLoading />}
                      </div>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        )}
      </Fragment>
    )
  }
}

export default AddUser
