import { React, useContext, useEffect, useState } from 'react'
import { AccountsContext } from '../contexts/AccountsContext'
import { useAuth } from '../../../components/Auth'
import ExpressClient from '../../../clients/express'
import usePopup from '../../../components/PopupMessage/hooks/usePopup'

import { get as lodashGet } from 'lodash'

const useAccounts = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user: authUser } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [state, setState] = useContext(AccountsContext)

  const [roles, setRoles] = useState([])
  const [users, setUsers] = useState([])
  const [crudCount, setCrudCount] = useState(0)
  const [editUser, setEditUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  const [permissionsDialogIsOpen, setPermissionsDialogIsOpen] = useState(false)
  const [editGroupDialogIsOpen, setEditGroupDialogIsOpen] = useState(null)

  // TABLE FOOTER/PAGINATION FUNCTIONS
  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (event, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const openEdit = user => {
    // console.log('useAccounts.openEdit.user:', JSON.stringify(user))
    setEditUser(user)
    setEditDialogIsOpen(true)
  }

  const closeEdit = () => {
    // if (!loading) {
    setLoading(false)
    setEditDialogIsOpen(false)
    setEditUser(null)
    // }
  }

  const updateUser = async values => {
    setLoading(true)
    try {
      await ec.request({
        method: 'patch',
        url: `/users/${values.id}`,
        data: values,
      })
      closeEdit()
      setCrudCount(crudCount + 1)
    } catch (error) {
      console.error('useAccounts.updateUser.error:', JSON.stringify(error))
      setLoading(false)
      const popupMessage = lodashGet(error, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const deleteUser = async () => {
    // console.log('useAccounts.deleteUser.editUser:', JSON.stringify(editUser))
    setLoading(true)
    try {
      await ec.request({
        method: 'delete',
        url: `/users/${editUser.id}`,
      })
      console.log('Users.deleteUser.deleteComplete.')
      closeEdit()
    } catch (error) {
      console.error('useAccounts.deleteUser.error:', JSON.stringify(error))
      const popupMessage = lodashGet(error, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      setLoading(false)
    }
    // Increase crud count to force users to reload
    setCrudCount(crudCount + 1)
  }

  const openAdd = () => {
    // console.log('useAccounts.openAdd.')
    setAddDialogIsOpen(true)
  }

  const closeAdd = () => {
    // if (!loading) {
    setLoading(false)
    setAddDialogIsOpen(false)
    // setAddUser(null)
    // }
  }

  const openPermissionsDialog = () => setPermissionsDialogIsOpen(true)
  const closePermissionsDialog = () => setPermissionsDialogIsOpen(false)

  const openEditGroupDialog = groupId => setEditGroupDialogIsOpen(groupId)
  const closeEditGroupDialog = () => setEditGroupDialogIsOpen(null)

  const createUser = async values => {
    setLoading(true)
    try {
      await ec.request({
        method: 'post',
        url: `/users`,
        data: values,
      })
      closeAdd()
    } catch (error) {
      console.error('useAccounts.createUser.error:', JSON.stringify(error))
      console.log('useAccounts.createUser.error: ', error.response.data.error.message)
      setLoading(false)
      const popupMessage = lodashGet(error, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
    setCrudCount(crudCount + 1)
  }

  const getUsers = () => {
    return ec.getUsers().then(res => {
      setUsers(res)
    })
  }

  // TO DO: no, somehow combine useUsers and useAccounts, and why is getRoles getting groups?
  const getRoles = async () => {
    try {
      const groups = await ec.get('/users/groups').then(res => {
        const defaultGroups = ['User', 'Admin', 'Super Admin']
        let groups = defaultGroups.reduce(
          (prevList, curGroup) =>
            res.data.groups.find(group => group.description === curGroup)
              ? [...prevList, res.data.groups.find(group => group.description === curGroup)]
              : prevList,
          []
        )
        return [
          ...groups,
          ...res.data.groups.filter(
            group => !defaultGroups.find(description => description === group.description)
          ),
        ]
      })
      setRoles(groups)
      return groups
    } catch {
      setRoles([])
      return []
    }
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    openEdit,
    closeEdit,
    updateUser,
    editDialogIsOpen,
    editUser,
    roles,
    users,
    getUsers,
    getRoles,
    createUser,
    deleteUser,
    crudCount,
    loading,
    openAdd,
    closeAdd,
    createUser,
    addDialogIsOpen,
    permissionsDialogIsOpen,
    openPermissionsDialog,
    closePermissionsDialog,
    openEditGroupDialog,
    closeEditGroupDialog,
    ec,
    authUser,
    lodashGet,
  }
}

export default useAccounts
