import * as React from 'react'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import IconButton from '@mui/material/IconButton'
import { EditMapping } from '../../Dialogs/Edit/EditMapping'
import { DeleteMapping } from '../../Dialogs/Delete/DeleteMapping'

import {
  MappingsTableRow,
  MappingsTableCell,
} from '@jeeves/pages/DataLabels/components/RepoRow/MappingTable/MappingTable.styles'

export const MappingRow = ({ mapping, labelName, datalabels, repoId, repoType }) => {
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  let cellValues = mapping.field.attribute.split('.')

  // for S3 repos, the location is comprised of a 'bucket', possibly followed by a 'key'
  // if the location includes a key, it can include one or more '.' delimiters, but should
  // still be considered a single key
  if (repoType === 's3' && cellValues.length > 2) {
    cellValues = [cellValues[0], cellValues.slice(1).join('.')]
  }

  return (
    <React.Fragment>
      <DeleteMapping
        repoId={repoId}
        attribute={cellValues}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        labelName={labelName}
      ></DeleteMapping>
      <EditMapping
        repoId={repoId}
        datalabels={datalabels}
        labelName={labelName}
        attribute={cellValues}
        open={editOpen}
        setOpen={setEditOpen}
        repoType={repoType}
      ></EditMapping>
      <MappingsTableRow>
        {cellValues.map(cellValue => (
          <MappingsTableCell>{cellValue}</MappingsTableCell>
        ))}
        {repoType === 's3' && cellValues.length === 1 && <MappingsTableCell>{}</MappingsTableCell>}
        <MappingsTableCell align="right">
          <IconButton fontSize="small" sx={{ mr: 3.5 }} onClick={() => setEditOpen(true)}>
            <EditIcon sx={{ color: 'cyralColors.grey.300' }} />
          </IconButton>
          <IconButton onClick={() => setDeleteOpen(true)}>
            <DeleteIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
          </IconButton>
        </MappingsTableCell>
      </MappingsTableRow>
    </React.Fragment>
  )
}
